@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  scroll-behavior: smooth;
  height: 100%;
  margin: 0;
}

body {
  background-color: black;
  height: 100%;
  overflow-x: hidden; /* Prevents horizontal scrolling */
  scroll-snap-type: y mandatory; /* Enables vertical scroll snapping */
}

section {
  height: 100vh; /* Each section takes up full viewport height */
  width: 100vw; /* Each section takes up full viewport width */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  scroll-snap-align: start; /* Each section aligns at the start */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.651);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.image-container:hover .overlay {
  opacity: 1;
}

.modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  opacity: 0;
}
